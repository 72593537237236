import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1080.000000 1080.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)">
          <path d="M0 5400 l0 -5400 5400 0 5400 0 0 5400 0 5400 -5400 0 -5400 0 0
-5400z m4775 1310 c46 -22 87 -66 100 -107 6 -22 4 -23 -43 -23 -45 0 -51 2
-62 30 -19 46 -86 74 -162 67 -64 -6 -97 -23 -132 -71 -16 -21 -22 -46 -25
-111 -4 -107 18 -159 80 -194 57 -33 156 -40 209 -16 l40 18 0 59 0 58 -75 0
-75 0 0 30 0 29 123 -2 122 -2 0 -100 0 -99 -57 -30 c-48 -24 -73 -30 -150
-33 -117 -6 -190 17 -253 82 -49 51 -65 98 -65 189 0 168 110 262 297 252 57
-2 96 -10 128 -26z m478 -259 c57 -28 122 -99 131 -142 11 -56 6 -149 -10
-187 -45 -109 -142 -152 -346 -152 l-128 0 0 256 0 256 153 -4 c133 -4 158 -7
200 -27z m-3017 -566 c27 -8 68 -30 91 -50 l41 -35 10 28 c6 15 13 35 16 45 5
16 17 17 108 15 l103 -3 3 -202 2 -203 -130 0 -130 0 0 25 c0 15 -7 40 -16 58
-62 120 -201 114 -279 -12 -27 -43 -29 -53 -30 -151 0 -130 22 -186 90 -225
88 -52 210 -20 210 55 0 34 -2 35 -40 38 l-40 3 3 75 4 74 226 -2 227 -3 3
-72 3 -72 -28 -3 -28 -3 -3 -172 -2 -173 -140 0 c-77 0 -140 3 -140 8 0 4 -9
22 -20 40 l-20 32 -21 -21 c-54 -54 -191 -82 -290 -59 -174 40 -302 182 -331
367 -42 270 75 510 290 594 59 23 189 25 258 4z m1344 -150 l0 -155 -120 0
-120 0 0 60 0 60 -110 0 -110 0 0 -90 0 -90 130 0 130 0 0 -95 0 -95 -130 0
-130 0 0 -110 0 -111 108 3 107 3 3 63 3 62 119 0 120 0 0 -160 0 -160 -415 0
-415 0 0 95 0 95 40 0 40 0 0 295 0 295 -40 0 -40 0 0 95 0 95 415 0 415 0 0
-155z m599 87 c11 -37 32 -110 45 -162 64 -246 71 -269 80 -259 1 2 30 112 63
244 l59 240 263 0 263 0 2 -90 1 -90 -37 -3 -38 -3 0 -295 0 -294 23 -1 c54
-3 52 1 51 -94 l0 -90 -217 -3 -217 -2 0 93 0 94 31 1 c17 0 33 3 35 5 2 2 3
114 2 248 l-3 244 -21 -95 c-19 -82 -95 -373 -141 -538 l-15 -53 -146 3 -147
3 -18 70 c-10 39 -27 99 -37 135 -11 36 -34 115 -50 175 -17 61 -37 135 -46
165 -9 30 -19 80 -24 110 -5 35 -7 -43 -6 -210 l1 -265 38 -3 37 -3 0 -90 0
-89 -187 2 -188 3 0 85 0 85 35 5 35 5 3 298 2 297 -35 0 -35 0 0 95 0 95 260
0 259 0 20 -68z m1421 48 c293 -75 432 -369 319 -671 -34 -89 -139 -197 -224
-228 -119 -45 -180 -52 -438 -49 l-242 3 -3 92 -3 92 43 3 43 3 0 290 0 290
-40 5 -40 5 -3 79 c-2 44 0 86 3 94 4 12 45 14 273 9 150 -3 287 -10 312 -17z
m1155 -55 c26 -82 79 -252 107 -345 11 -36 32 -103 47 -150 16 -47 36 -113 46
-147 l19 -63 48 0 48 0 0 -95 0 -95 -275 0 -275 0 0 95 0 95 60 0 c66 0 72 8
46 58 l-14 27 -133 -1 -133 0 -18 -40 -17 -39 55 -3 54 -3 0 -94 0 -95 -220 0
-220 0 0 95 0 95 48 0 47 0 32 108 c17 59 56 188 87 287 31 99 56 185 56 191
0 6 -10 10 -22 10 -22 -1 -23 3 -26 78 -2 44 -1 85 2 93 5 11 54 13 267 11
l262 -3 22 -70z m1265 -110 l0 -186 -102 3 -103 3 -3 88 -3 87 -49 0 c-43 0
-50 -3 -55 -22 -3 -13 -4 -144 -3 -293 l3 -270 58 -3 57 -3 0 -95 0 -94 -257
2 -258 3 -3 92 -3 92 58 3 58 3 0 290 0 290 -57 3 -58 3 0 -91 0 -91 -102 3
-103 3 -3 170 c-1 93 0 175 2 182 4 10 100 13 466 13 l460 0 0 -185z m809 83
c18 -57 45 -143 61 -193 16 -49 54 -173 85 -275 31 -102 58 -190 60 -197 2 -6
26 -13 52 -15 l48 -3 3 -92 3 -93 -281 0 -281 0 3 93 3 92 61 5 61 5 -11 39
-11 39 -135 -1 c-74 0 -138 -1 -141 -1 -4 -1 -11 -19 -17 -41 l-10 -40 48 0
c34 0 50 -4 54 -16 9 -23 7 -147 -2 -162 -7 -9 -58 -12 -223 -10 l-214 3 0 90
0 90 50 5 c40 4 51 9 54 25 3 20 155 517 166 547 5 12 0 17 -20 20 -26 3 -27
3 -27 88 0 47 1 88 1 93 1 4 120 7 264 7 l264 0 32 -102z m-2971 -1250 l2
-198 -30 0 -30 0 0 193 c0 107 3 197 7 201 4 4 16 6 28 4 19 -3 20 -10 23
-200z m532 133 l0 -69 31 15 c65 33 135 -9 150 -91 25 -133 -58 -231 -153
-182 -22 12 -27 12 -32 1 -3 -8 -13 -11 -25 -8 -19 5 -20 14 -23 200 -2 107
-1 197 1 199 2 2 15 4 28 4 22 0 23 -3 23 -69z m296 -133 l1 -198 -29 0 -28 0
0 201 0 200 28 -3 27 -3 1 -197z m-1244 143 c41 -46 40 -111 -2 -153 -35 -35
-34 -40 15 -123 19 -31 35 -59 35 -62 0 -2 -15 -3 -32 -1 -30 3 -37 10 -71 76
-37 71 -39 72 -77 72 l-40 0 0 -75 0 -75 -30 0 -30 0 0 191 0 192 102 -6 c99
-5 104 -6 130 -36z m538 14 c0 -31 -26 -43 -47 -22 -21 21 -9 47 22 47 20 0
25 -5 25 -25z m1340 15 c47 -25 41 -114 -10 -150 l-30 -21 36 -45 c38 -46 50
-47 56 -4 2 17 10 26 26 28 18 3 22 -1 22 -25 -1 -15 -7 -41 -14 -58 -12 -27
-10 -33 12 -62 l24 -33 -35 0 c-19 0 -37 6 -40 13 -3 9 -9 9 -24 0 -34 -20
-90 -26 -127 -13 -48 16 -76 53 -76 103 0 32 6 46 34 72 34 31 34 32 15 56
-27 35 -25 94 6 124 26 27 89 34 125 15z m518 -17 c3 -22 1 -23 -92 -23 l-96
0 0 -60 0 -60 80 0 c79 0 80 0 80 -25 0 -25 -1 -25 -80 -25 l-80 0 0 -80 c0
-79 0 -80 -25 -80 l-25 0 0 190 0 191 118 -3 c113 -3 117 -4 120 -25z m672
-38 c0 -31 3 -35 25 -35 18 0 25 -5 25 -20 0 -15 -7 -20 -25 -20 -25 0 -25 -1
-25 -84 0 -97 5 -116 31 -116 14 0 19 -7 19 -25 0 -21 -5 -25 -30 -25 -57 0
-70 27 -70 145 l0 105 -25 0 c-18 0 -25 5 -25 20 0 15 7 20 25 20 22 0 25 4
25 35 0 31 3 35 25 35 22 0 25 -4 25 -35z m-2825 -39 c41 -17 65 -60 65 -116
0 -33 -3 -41 -17 -39 -10 1 -51 1 -90 0 -81 -1 -88 -9 -52 -55 24 -30 71 -35
106 -11 20 14 53 11 53 -4 0 -19 -52 -53 -87 -58 -63 -8 -110 17 -135 73 -36
83 -10 169 63 206 41 22 50 22 94 4z m520 0 c18 -8 38 -23 43 -33 6 -10 12
-69 14 -131 l3 -112 -27 0 c-16 0 -28 4 -28 9 0 5 -14 3 -31 -6 -66 -33 -139
7 -139 77 0 62 31 83 135 92 59 6 34 67 -27 68 -17 0 -33 -8 -40 -20 -11 -18
-68 -30 -68 -14 0 17 25 47 53 64 38 24 66 25 112 6z m700 4 c39 -15 53 -33
66 -83 20 -71 13 -76 -86 -75 -47 0 -88 -2 -91 -5 -9 -8 24 -64 41 -71 28 -10
60 -7 83 10 26 18 52 12 52 -11 0 -20 -58 -55 -93 -55 -15 0 -43 6 -62 14
-100 42 -102 228 -2 271 39 17 57 18 92 5z m1142 -10 c37 -22 41 -39 45 -178
l3 -92 -27 0 c-16 0 -28 5 -28 10 0 6 -14 3 -31 -6 -38 -19 -91 -12 -120 17
-19 19 -25 71 -13 104 10 25 58 45 108 45 53 0 64 12 42 45 -20 31 -64 33 -94
5 -24 -23 -62 -27 -62 -6 0 19 47 64 73 69 48 9 73 6 104 -13z m300 -13 c30
-31 34 -61 7 -65 -14 -2 -27 6 -38 22 -27 42 -96 31 -96 -15 0 -13 16 -22 56
-34 97 -28 131 -89 80 -140 -27 -27 -82 -41 -122 -31 -32 8 -84 58 -84 80 0
11 9 16 30 16 20 0 30 -5 30 -15 0 -30 82 -49 107 -24 12 12 6 39 -11 51 -6 4
-35 15 -63 23 -66 19 -90 50 -77 98 19 65 129 86 181 34z m-2367 -117 l0 -140
-25 0 -25 0 0 140 0 140 25 0 25 0 0 -140z"/>
<path d="M4990 6225 l0 -195 68 0 c112 0 141 9 188 55 34 35 43 51 49 92 11
81 -3 140 -42 184 -42 46 -82 59 -185 59 l-78 0 0 -195z"/>
<path d="M5382 5408 l3 -293 57 1 c98 2 155 62 184 195 45 213 -45 389 -199
389 l-47 0 2 -292z"/>
<path d="M6448 5547 c-15 -51 -34 -114 -42 -140 l-15 -48 84 2 c55 1 85 5 85
13 0 6 -13 53 -29 103 -16 51 -32 106 -36 123 -4 16 -10 32 -13 34 -4 2 -19
-37 -34 -87z"/>
<path d="M8540 5638 c0 -2 -18 -65 -40 -138 -22 -73 -40 -135 -40 -137 0 -2
38 -3 85 -3 47 0 85 2 85 5 0 11 -81 269 -85 274 -3 2 -5 2 -5 -1z"/>
<path d="M6412 4564 c-19 -13 -22 -24 -22 -84 0 -60 3 -71 22 -84 28 -20 58
-20 84 -2 16 12 19 27 19 86 0 59 -3 74 -19 86 -26 18 -56 18 -84 -2z"/>
<path d="M5270 4600 l0 -70 40 0 c54 0 100 24 101 53 4 66 -18 87 -88 87 l-53
0 0 -70z"/>
<path d="M7236 4658 c-19 -27 -20 -38 -2 -61 12 -15 18 -16 35 -8 35 19 45 50
25 72 -22 25 -39 24 -58 -3z"/>
<path d="M7206 4478 c-34 -48 -6 -98 53 -98 63 0 70 21 25 75 -43 51 -56 55
-78 23z"/>
<path d="M5591 4554 c-12 -15 -21 -31 -21 -35 0 -5 29 -9 65 -9 70 0 78 7 49
48 -22 31 -67 29 -93 -4z"/>
<path d="M6108 4459 c-21 -12 -24 -49 -6 -67 32 -32 108 2 108 49 0 27 -2 29
-42 29 -24 0 -51 -5 -60 -11z"/>
<path d="M6822 4564 c-12 -8 -22 -24 -22 -35 0 -17 7 -19 60 -19 52 0 60 3 60
18 0 24 -31 52 -56 52 -11 0 -30 -7 -42 -16z"/>
<path d="M7938 4459 c-21 -12 -24 -49 -6 -67 33 -33 108 5 108 53 0 23 -4 25
-42 25 -24 0 -51 -5 -60 -11z"/>
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
